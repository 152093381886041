<template>
  <div style="display: flex;justify-content: center;cursor: pointer;position:relative;">
    <img :src="src" alt="" style="height: 100%" @click="showImg()" />
    <div v-if="preview" id="imgMask" @click="preview=false"></div>
    <div v-if="preview" class="big" @click="preview=false">
      <!-- <div id="close" class="buttons">
        <i v-if="show" class="pi pi-search-plus" style="font-size: 15px" @click="enlarge"></i>
        <i v-else class="pi pi-search-minus" style="font-size: 15px" @click="ensmall"></i>
         <i class="pi pi-times" style="font-size: 15px"></i>
      </div> -->
      <img :id="uuid" :src="src" alt="" @click="preview=false;" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Image",
  props: {
    src: {
      default: null,
    },
  },
  data() {
    return {
      preview: false,
      uuid: false,
    };
  },
  created() {
    this.uuid = Math.floor(Math.random() * 10000000);
  },
  methods: {
    showImg() {
      this.show = true;
      const _this = this;
      this.preview = true;
      _this.$nextTick(() => {
        let mask = document.getElementById("imgMask");
        let ele = document.getElementById(this.uuid);
        let img = document.createElement("img");
        img.src = this.src;
        img.onload = function () {
          // let close = document.getElementById("close");
          ele.style.position = "fixed";
          ele.style.zIndex = "9999";
          mask.style.width = 2*window.screen.width + "px";
          mask.style.height = 2*window.screen.height + "px";
          mask.style.position = "fixed";
          mask.style.top = '-20%';
          mask.style.left = '-70%';
          // mask.style.zIndex = "1150";
          mask.style.background = "#000";
          mask.style.opacity = "0.2";
          if (img.width > img.height) {
            if (ele) {
              ele.style.width = window.screen.width + "px";
              ele.style.height = "auto";
              // close.style.position = "absolute";
              // close.style.top = -40 + "px";
              // console.log(window.screen.width);
              // close.style.left = 238+'px' ;
              // close.style.marginLeft='100%'
              // close.style.left = ele.offsetWidth + "px";
            }
          } else {
            if (ele) {
              // console.log(offsetWidth);
              ele.style.width = "auto";
              ele.style.height = window.screen.height - 100 + "px";
              // close.style.position = "fixed";
              // close.style.top = -40 + "px";
              // close.style.left =window.screen.width - 810 + "px";
            }
          }
          // close.style.backgroundColor = "black";
          // close.style.zIndex = "999";
          // close.style.opacity = "0.3";
        };
      });
    },
  },
};
</script>

<style scoped>
.big {
  display: flex;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 999;
}

.big img {
  height: 70%;
}

.big .buttons {
  position: absolute;
  width: 60px;
  height: 30px;
  top: 1rem;
  right: 1rem;
  text-align: right;
  color: white;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>