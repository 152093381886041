<template>
  <div class="card" v-if="type && item">
    <h5>{{type?type:''}}</h5>
    <div style="height: 100px" class="record-img" v-for="img in item.images">
      <my-image style="height: 100%" :src="$minioUrl+img.imgPath"/>
    </div>
    <div class="record-line" v-if="item.typem&&item.typem==='草种准备'">
      费用：{{ item.price }}元&nbsp;&nbsp;&nbsp;&nbsp;
      准备内容：{{ item.content == 0 ? '草种选择' : (item.content == 1 ? ' 草种截断' : (item.content == 2 ? '催芽' : null)) }}
      截断方式：{{ item.type == 0 ? '扦插种植' : (item.type == 1 ? '育苗移栽' : (item.type == 2 ? '平地条栽' : (item.type == 3
        ? '坡地条栽'
        : null))) }}
    </div>
    <div class="record-line" v-if="item.type==='施用底肥'">
      费用：{{ item.price }}元&nbsp;&nbsp;&nbsp;&nbsp;
      肥料类型：{{ item.fertilizerType == 0 ? '化肥' : '农家肥' }}
      肥料：<span v-for="fer in item.fertilizeLists">
            {{ fer.name }}{{ fer.kilo }}公斤
          </span>
    </div>
    <div class="record-line" v-if="item.type==='土地准备'">
      作业方式：{{ item.workType == 0 ? '人工' : '机械' }}&nbsp;&nbsp;&nbsp;&nbsp;
      准备内容：
      {{ item.content == 0 ? '清除杂草' : (item.content == 1 ? ' 翻耕(旋耕)' : (item.content == 2
        ? '挖排涝渠'
        : (item.content == 3 ? '通道规划' : null))) }}&nbsp;&nbsp;&nbsp;&nbsp;
      价格：{{ item.price }}元
    </div>

    <div class="record-line" v-if="item.type==='种植记录'">数量：{{ item.num
      }}棵&nbsp;&nbsp;&nbsp;&nbsp; 费用：{{ item.price }}元&nbsp;&nbsp;&nbsp;&nbsp;
      种植方式：{{ item.plantType == 0 ? '扦插' : (item.plantType == 1 ? '条栽' : (item.plantType == 2
          ? '育苗移栽'
          : (item.plantType == 3 ? '补苗' : '-'))) }}
    </div>
    <div class="record-line" v-if="item.type==='施肥记录'">
      面积：{{ item.area }}亩&nbsp;&nbsp;&nbsp;&nbsp;
      肥料：<span v-for="fer in item.fertilizeLists">
            {{ fer.name }}{{ fer.kilo }}公斤
          </span>
    </div>
    <div class="record-line" v-if="item.type==='灌溉记录'">
      灌溉方式：{{ item.irrigationType == 0 ? '首次灌溉' : (item.irrigationType == 1 ? '后期灌溉' : '-') }}&nbsp;&nbsp;&nbsp;&nbsp;
      面积：{{ item.area }}亩&nbsp;&nbsp;&nbsp;&nbsp;
      用水量：{{ item.waterCons }}吨&nbsp;&nbsp;&nbsp;&nbsp;
      费用：{{ item.price }}元
    </div>
    <div class="record-line" v-if="item.type==='水肥一体化记录'">
      面积：{{ item.area }}亩&nbsp;&nbsp;&nbsp;&nbsp;
      用水量：{{ item.waterCons }}吨&nbsp;&nbsp;&nbsp;&nbsp;
      肥料：<span v-for="fer in item.integrationList">
            {{ fer.fertilizerName }}{{ fer.kilo }}公斤
          </span>
    </div>
    <div class="record-line" v-if="item.type ==='除虫记录'">
      面积：{{ item.area }}亩&nbsp;&nbsp;&nbsp;&nbsp;
      费用：{{ item.price }}元&nbsp;&nbsp;&nbsp;&nbsp;
      农药：<span v-for="fer in item.plantDewormingLists">
            {{ fer.insecticideName }}{{ fer.kilo }}公斤
          </span>
    </div>

    <div style="width: 100%;display: block;height: 40px;line-height: 40px;font-size: 15px" v-if="item.type==='收割记录'">
      面积：{{ item.area }}亩
      &nbsp;&nbsp;&nbsp;&nbsp;
      产量：{{ item.yield }}吨&nbsp;&nbsp;&nbsp;&nbsp;
      费用：{{ item.price }}元
    </div>

    <div class="record-line" v-if="item.type==='出苗记录'">出苗率：{{ item.emergenceRate }}%</div>
    <div class="record-line" v-if="item.type==='拔节记录'">高度：{{ item.height }}cm</div>
    <div class="record-line" v-if="item.type==='植株测量'">
      测量编号：{{ item.no }}&nbsp;&nbsp;&nbsp;&nbsp;
      丛重：{{ item.congzhong }}克&nbsp;&nbsp;&nbsp;&nbsp;
      分蘖数：{{ item.fennieshu }}根&nbsp;&nbsp;&nbsp;&nbsp;
      单株高：{{ item.danzhugao }}cm&nbsp;&nbsp;&nbsp;&nbsp;
      单株重：{{ item.danzhuzhong }}克&nbsp;&nbsp;&nbsp;&nbsp;
      单株茎直径：{{ item.danzhujingzhijing }}cm&nbsp;&nbsp;&nbsp;&nbsp;
      单株节数：{{ item.danzhujieshu }}节&nbsp;&nbsp;&nbsp;&nbsp;
      单株节间距：{{ item.danzhujiejianju }}cm&nbsp;&nbsp;&nbsp;&nbsp;
      叶长：{{ item.yechang }}cm&nbsp;&nbsp;&nbsp;&nbsp;
      叶宽：{{ item.yekuan }}cm&nbsp;&nbsp;&nbsp;&nbsp;
      绿叶数：{{ item.lvyeshu }}个&nbsp;&nbsp;&nbsp;&nbsp;
      黄叶数：{{ item.huangyeshu }}个&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="record-line" v-if="item.type==='分蘖记录'">
      最高：{{ item.rateMax }}&nbsp;&nbsp;&nbsp;&nbsp;
      最低：{{ item.rateMin }}&nbsp;&nbsp;&nbsp;&nbsp;
      平均：{{ item.rateAvg }}
    </div>

    <div class="record-line" v-if="item.type==='测产记录'">面积：{{ item.area }}亩 &nbsp;&nbsp;&nbsp;&nbsp;产量：{{ item.yield
      }}吨
    </div>
  </div>
</template>

<script>
import myImage from '@/views/modules/chat/myImage';
export default {
  name: 'recordInfo',
  data() {
    return {
      item: null,
      type:null
    };
  },
  components:{myImage},
  mounted() {
    this.type = this.$route.query.type;
    let id = this.$route.query.id;
    let projectId = this.$route.query.projectId
    this.$axios.get('/recPlantPlant/getRecAllRecord/' + projectId).then(res => {
      if (res.data) {
        res.data.forEach(item => {
          if ((item.type == this.type || item.typem == this.type) && item.id == id) {
            this.item = item;
          }
        });
      }
    });
  },
};
</script>

<style scoped>

</style>